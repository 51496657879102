import {
  getCurrentSite
} from './url'

const originApp = {
  PARAGON_SALESFORCE: 'paragon_salesforce',
  PARAGON_HUBSPOT: 'paragon_hubspot',
  ZAPIER: 'zapier',
  CSV_ENRICHMENT: 'csv_enrichment',
  CHROME_GMAIL_WEB: 'chrome_extension_gmail_web',
  CHROME_OUTLOOK_WEB: 'chrome_extension_outlook_web',
  CHROME_LINKEDIN: 'chrome_extension_linkedin',
  CHROME_LINKEDIN_SALES_NAVIGATOR: 'chrome_extension_linkedin_sales_navigator',
  CHROME_LINKEDIN_RECRUITER: 'chrome_extension_linkedin_recruiter',
  CHROME_HUBSPOT_WEB: 'chrome_extension_hubspot_web',
  CHROME_SALESFORCE_WEB: 'chrome_extension_salesforce_web',
  CHROME_OUTREACH: 'chrome_extension_outreach',
  CHROME_GOOGLE_MEET: 'chrome_extension_google_meet',
  DEFAULT: 'default'
}

const platformToOriginApp = {
  'gmail': 'chrome_extension_gmail_web',
  'linkedin': 'chrome_extension_linkedin',
  'outlook': 'chrome_extension_outlook_web',
  'outlookReply': 'chrome_extension_outlook_web',
  'salesforce': 'chrome_extension_salesforce_web',
  'gmailInline': 'chrome_extension_gmail_web',
  'hubspot': 'chrome_extension_hubspot_web'
}

const domainToOriginApp = {
  'Linkedin Recruiter': originApp.CHROME_LINKEDIN_RECRUITER,
  'Sales Navigator': originApp.CHROME_LINKEDIN_SALES_NAVIGATOR,
  'Linkedin': originApp.CHROME_LINKEDIN,
  'HubSpot': originApp.CHROME_HUBSPOT_WEB,
  'Gmail': originApp.CHROME_GMAIL_WEB,
  'Outlook': originApp.CHROME_OUTLOOK_WEB,
  'Outreach': originApp.CHROME_OUTREACH,
  'Salesforce': originApp.CHROME_SALESFORCE_WEB,
  'Meet': originApp.CHROME_GOOGLE_MEET,
  'Unknown': originApp.DEFAULT
}

const getOriginApp = () => {
  const currentApp = getCurrentSite()
  return domainToOriginApp[currentApp]
}

export { originApp, platformToOriginApp, getOriginApp }
