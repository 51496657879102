export default {
  apiUrl: 'https://staging-api.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.staging.crystalknows.com',
  appUrl: 'http://localhost:3000',
  cookieDomain: 'localhost',
  devMode: true,
  hubURL: 'https://hub.integration.crystalknows.com',
  marketingUrl: 'https://staging.crystalknows.com',
  outreachClientId: '--IjYhC-xlsSfEXz88mpRf9P3xprvD7Nmq3Z04vDrPk',
  pk_live: 'pk_test_2DwpK6wrrckZ87ZjEfMsyabr',
  screenshotApiUrl:
    'https://fvxxcux5h6.execute-api.us-east-1.amazonaws.com/prod',
  rudderStackWriteKey: '1kU6UMcvdKczMrftJNVU8VvT4oZ',
  useDevtoolsExtension: true,
  useFullStory: false,
  useHubspotAnalytics: false,
  slack_url: 'https://hooks.slack.com',
  authTokenName: 'CRYSTAL_DEV_AUTH',
  sessionTokenName: 'CRYSTAL_DEV_SESS',
  pageTokenName: 'CRYSTAL_DEV_PAGE',
}
