const isDebugMode = () => {
  const isNotProd = process.env.NODE_ENV !== 'production'
  const enabledManually =
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('crystal-debug-logs') == 'true'

  return isNotProd || enabledManually
}

export default {
  debug: function (name: string, ...args: any[]) {
    if (!isDebugMode()) return

    /* eslint-disable no-debugger, no-console */
    console.log(
      `%c Debug - ${name}:`,
      'color: #7e7e7e; font-weight: bold;',
      ...args,
    )
  },
  info: function (name: string, ...args: any[]) {
    /* eslint-disable no-debugger, no-console */
    console.log(
      `%c Info - ${name}:`,
      'color: #000; font-weight: bold;',
      ...args,
    )
  },
  warn: function (name: string, ...args: any[]) {
    /* eslint-disable no-debugger, no-console */
    console.log(
      `%c Warn - ${name}:`,
      'background: yellow; color: #000;  font-weight: bold;',
      ...args,
    )
  },
  error: function (name: string, ...args: any[]) {
    /* eslint-disable no-debugger, no-console */
    console.error(
      `%c Error - ${name}:`,
      'background: red; color: #fff;  font-weight: bold;',
      ...args,
    )
  },
}
