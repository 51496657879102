interface OpenWindowOptions {
  path: string
  windowName: string // Should be single spaced to support IE
  windowOptions?: string
  width?: number
  height?: number
  callback(): void
}

export const chrome_extension_installed_cookie = 'chrome_extension_installed'

export function openWindow(options: OpenWindowOptions) {
  options.width = options.width || 800
  options.height = options.height || 800
  options.windowOptions = `location=0,status=0,width=${options.width},height=${options.height}`

  const openedWindow = window.open(
    options.path,
    options.windowName,
    options.windowOptions,
  )

  if (openedWindow) {
    const windowInterval = window.setInterval(() => {
      if (openedWindow && openedWindow.closed) {
        window.clearInterval(windowInterval)
        options.callback()
      }
    }, 1000)
  } else {
    window.open(options.path, options.windowName)
  }
}

export async function getCookie(
  cookieName: string,
): Promise<void | string | null> {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  // @ts-ignore
  if (typeof chrome !== 'undefined' && chrome?.runtime?.id) {
    // @ts-ignore
    return chrome.runtime.sendMessage({
      type: 'BROWSER_GET_COOKIE',
      name: cookieName,
    })
  } else {
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trimLeft()

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
  }

  return null
}

export function getDocumentCookie(cookieName: string): string | null {
  if (typeof document === 'undefined') return null

  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trimLeft()

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }

  return null
}

export async function deleteCookie(cookieName: string) {
  // @ts-ignore
  if (typeof chrome !== 'undefined' && chrome?.runtime?.id) {
    // @ts-ignore
    return chrome.runtime.sendMessage({
      type: 'BROWSER_DELETE_COOKIE',
      name: cookieName,
    })
  } else {
    setCookie(cookieName, '', { expires: 'Thu, 01 Jan 1970 00:00:00 UTC' })
  }
}

type CookieOpts = {
  domain?: string
  expires?: string
}

export async function setCookie(
  name: string,
  value: string,
  opts: CookieOpts = {},
) {
  const { domain, expires } = opts

  const defaultDate = new Date()
  defaultDate.setMonth(defaultDate.getMonth() + 12)

  let domainSection
  if (domain) {
    domainSection = `domain=${domain}`
  } else {
    domainSection = `domain=${window?.location?.hostname}`
  }

  const cookieStr = [
    `${name}=${value}`,
    domainSection,
    'path=/',
    `expires=${expires || defaultDate}`,
    'Secure',
  ].join(';')

  document.cookie = cookieStr
}
