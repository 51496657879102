export default {
  apiUrl: 'https://api.integration.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.staging.crystalknows.com',
  appUrl: 'https://integration.crystalknows.com',
  cookieDomain: 'crystalknows.com',
  devMode: false,
  hubURL: 'https://hub.integration.crystalknows.com',
  marketingUrl: 'https://integration.crystalknows.com',
  outreachClientId: '--IjYhC-xlsSfEXz88mpRf9P3xprvD7Nmq3Z04vDrPk',
  pk_live: 'pk_test_2DwpK6wrrckZ87ZjEfMsyabr',
  screenshotApiUrl:
    'https://fvxxcux5h6.execute-api.us-east-1.amazonaws.com/prod',
  rudderStackWriteKey: '1kU6UMcvdKczMrftJNVU8VvT4oZ',
  useDevtoolsExtension: true,
  useFullStory: false,
  useHubspotAnalytics: false,
  slack_url: 'https://hooks.slack.com',
  authTokenName: 'CRYSTAL_INTEG_AUTH',
  sessionTokenName: 'CRYSTAL_INTEG_SESS',
  pageTokenName: 'CRYSTAL_INTEG_PAGE',
}
