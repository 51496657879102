export default {
  apiUrl: 'https://api.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.crystalknows.com',
  appUrl: 'https://www.crystalknows.com',
  cookieDomain: 'crystalknows.com',
  devMode: false,
  hubURL: 'https://hub.crystalknows.com',
  marketingUrl: 'https://www.crystalknows.com',
  outreachClientId: 'qVYzAatYNtKnmXbl5u6lMnb8653XUtPMYcarQvT0eZU',
  pk_live: 'pk_live_OZGp67qDSVKzl0S91k234Izk',
  screenshotApiUrl:
    'https://fvxxcux5h6.execute-api.us-east-1.amazonaws.com/prod',
  rudderStackWriteKey: '1lkCRX5aDsbofHEg8ET8Kyz4vuc',
  useDevtoolsExtension: false,
  useFullStory: true,
  useHubspotAnalytics: true,
  slack_url: 'https://hooks.slack.com',
  authTokenName: 'CRYSTAL_AUTH_TOKEN',
  sessionTokenName: 'CRYSTAL_SESSION_TOKEN',
  pageTokenName: 'CRYSTAL_PAGE_TOKEN',
}
