import { v4 as uuidv4 } from 'uuid'
import jwtDecode from 'jwt-decode'
import { AuthDetails, JwtPayload } from '@crystal-eyes/types'
import {
  deleteCookie,
  getCookie,
  getDocumentCookie,
  setCookie,
} from '@crystal-eyes/lib/browser'
import { AuthSource, authSource } from '@crystal-eyes/config'

export type { AuthDetails }

export function getAuthDetails(): Promise<AuthDetails> {
  if (!authSource)
    throw new Error('Called _getAuthDetails() before authSource set')

  if (authSource.type === 'cookies') {
    return _getAuthCookieDetails(authSource)
  }
  throw new Error(
    `Asked _getAuthDetails() with unknown authSource type ${authSource.type}`,
  )
}

async function _getAuthCookieDetails(source: AuthSource): Promise<AuthDetails> {
  const authToken = await getCookie(source.auth)
  let sessionToken = await getSessionToken()

  const jwtDecoded = authToken
    ? (jwtDecode(authToken) as JwtPayload)
    : undefined

  return {
    authed: !!authToken,
    authToken,
    sessionToken,
    jwtPayload: jwtDecoded,
    appName: getApp(),
  }
}

export function getAuthFallback(): AuthDetails | {} {
  if (
    typeof document === 'undefined' ||
    typeof document?.cookie === 'undefined' ||
    !authSource
  ) {
    return {}
  }

  const authToken = getDocumentCookie(authSource.auth)
  let sessionToken = getDocumentSessionToken()

  const jwtDecoded = authToken
    ? (jwtDecode(authToken) as JwtPayload)
    : undefined

  return {
    authed: !!authToken,
    authToken,
    sessionToken,
    jwtPayload: jwtDecoded,
    appName: getApp(),
  }
}

export async function getSessionToken(): Promise<string> {
  if (!authSource)
    throw new Error('Called getSessionToken() before authSource set')

  if (authSource.type === 'cookies') {
    return getCookieSessionToken(authSource)
  }

  throw new Error(
    `Asked getSessionToken() with unknown authSource type ${authSource.type}`,
  )
}

export function getDocumentSessionToken(): string | null {
  if (!authSource)
    throw new Error('Called getSessionToken() before authSource set')

  return getDocumentCookie(authSource.session)
}

async function getCookieSessionToken(source: AuthSource): Promise<string> {
  let sessionToken = await getCookie(source.session)

  if (!sessionToken) {
    sessionToken = uuidv4()
    setCookie(source.session, sessionToken)
  }

  return sessionToken
}

export function getApp(): string {
  return 'dashboard'
}

export async function doSetAuthDetails(
  authSource: AuthSource,
  details: AuthDetails,
) {
  if (authSource.type === 'cookies') {
    return doSetCookieDetails(authSource, details)
  }
}

async function doSetCookieDetails(
  authSource: AuthSource,
  details: AuthDetails,
) {
  if (details.authToken) {
    setCookie(authSource.auth, details.authToken)
  } else {
    deleteCookie(authSource.auth)
    deleteCookie(authSource.session)
  }

  if (details.sessionToken) {
    setCookie(authSource.session, details.sessionToken)
  }
}
