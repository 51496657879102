import { AuthDetails } from '@crystal-eyes/utils/auth'
import { v3ApiUrl } from '@crystal-eyes/config'
import { getOriginApp } from '@crystal-eyes/lib/origin_app'
import { trackExecution } from '@crystal-eyes/utils/instrumentation'
import getAuth from '@crystal-eyes/data/getAuth'

export function fetchV3ApiAuthed(
  auth: AuthDetails | undefined | null,
  path: string,
  options: RequestInit & { fetch?: any } = {},
  params?: Record<string, any>,
): Promise<Response> {
  const authHeaders: any = auth?.authToken
    ? {
        Authorization: `Bearer ${auth.authToken}`,
        Session: auth.sessionToken || '',
        App: auth.appName || 'dashboard-unknown-app',
        'Origin-App': getOriginApp(),
      }
    : {}

  if (!authHeaders)
    return Promise.reject('No auth headers sent to fetchV3ApiAuthed')
  const queryString = params ? objectToQueryString(params) : ''
  const url = `${v3ApiUrl}/v3/${path}${queryString}`

  const authedOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers || {}),
      ...(process.env.RUNTIME_ENV === 'server'
        ? { 'x-crystal-nextjs': '245e5f31-d71c-4403-bec2-8f05f0c46982' }
        : {}),
      ...authHeaders,
    },
  }

  const chosenFetch = options?.fetch ? options.fetch : fetch

  return trackExecution<Response>(`V3 API: ${path}`, () => {
    return chosenFetch(url, authedOptions)
  })
}

export function fetchV3Api(path: string, options: RequestInit = {}) {
  const url = `${v3ApiUrl}/v3/${path}`

  return trackExecution<Response>(`V3 API Unauthed: ${path}`, () => {
    return fetch(url, {
      ...options,
      headers: {
        ...(options.headers || {}),
        ...(process.env.RUNTIME_ENV === 'server'
          ? { 'x-crystal-nextjs': '245e5f31-d71c-4403-bec2-8f05f0c46982' }
          : {}),
        'Content-Type': 'application/json',
      },
    })
  })
}

function objectToQueryString(params: Record<string, any>) {
  return `?${Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')}`
}

export const authedFetchV3Api = async (
  path: string,
  options: RequestInit & { fetch?: any } = {},
  params?: Record<string, any>,
): Promise<any> => {
  const authData = await getAuth()
  return fetchV3ApiAuthed(authData, path, options, params)
}
