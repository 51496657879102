export default function getAuth() {
  if (process.env.RUNTIME_ENV === 'server') {
    return import('@crystal-eyes/utils/serverAuth').then(({ getAuthDetails }) =>
      getAuthDetails(),
    )
  } else {
    return import('@crystal-eyes/utils/auth').then(({ getAuthDetails }) =>
      getAuthDetails(),
    )
  }
}
