import qs from 'qs'

export const isLinkedin = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com/)
}

export const isLICDNPhoto = (URL: string) => {
  if (!URL) return false
  return !!URL.includes('licdn.com')
}

export const isLinkedinMeUrl = (URL?: string | null) => {
  const url = URL || window.location.href
  return url.match(/^https?:\/\/(?:www\.)?linkedin\.com\/in\/me($|\/[^/\s]*$)/)
}

export const validateLinkedinProfileUrl = (url: string) => {
  return !!url.match(/https?:\/\/(www\.)?linkedin\.com\/in\/[^\/\s]+/)
}

export const isLinkedinProfile = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.includes('linkedin.com/in/')
}

export const isLinkedinRecruiterProfile = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.includes('linkedin.com/recruiter/profile')
}

export const isLinkedInTalentProfile = (URL?: string | null) => {
  const href = URL || window.location.href
  return (
    !!href.match(/linkedin.com\/talent\/profile/) ||
    !!href.match(/linkedin.com\/talent\/search\/profile/)
  )
}

export const isLinkedInMessaging = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com\/messaging\/thread\/\w+/i)
}

export const isLinkedInSearchResults = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com\/search\/results/i)
}

export const isMyNetwork = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com\/mynetwork\//i)
}

export const isCompanyPage = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com\/company\//i)
}

export const isNotificationsPage = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com\/notifications\//i)
}

export const isSalesNavigatorProfile = (URL?: string | null) => {
  const url = URL || window.location.href

  return (
    !!url.match(/linkedin.com\/sales\/people\/\w+/i) ||
    !!url.match(/linkedin.com\/sales\/lead\/\w+/i)
  )
}

export const isSalesNavigatorInbox = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/linkedin.com\/sales\/inbox\/compose/i)
}

export const isHubspot = (URL?: string | null) => {
  const url = URL || window.location.href

  return !!url.match(/hubspot.com/)
}

export const isHubspotContact = (URL?: string | null) => {
  const url = URL || window.location.href
  if (url.match(/hubspot\.com\/contacts\/(\d+)\/record\/0-1\/(.*)/)) {
    return true
  } else if (url.match(/hubspot\.com\/contacts\/(\d+)\/contact\/(.*)/)) {
    return true
  }
  return false
}

export const isSalesforceClassic = (url: string) => {
  return !!url.match(/salesforce\.com/)
}

export const isSalesforceLightning = (url: string) => {
  return !!url.match(/lightning\.force\.com/)
}

export const isSalesforce = (url: string) => {
  return isSalesforceClassic(url) || isSalesforceLightning(url)
}

export const isOutreach = (url: string | null) => {
  return url && url.includes('outreach.io')
}

export const isOutreachProspect = (URL?: string | null) => {
  const url = URL || window.location.href

  return url.includes('outreach.io/prospects/') && url.includes('/overview')
}

export const isSalesforceContact = (url: string) => {
  return (
    isSalesforce(url) &&
    ((url.includes('/Contact/') && !url.includes('Contact/list')) ||
      !!url.match(/salesforce.com\/\w+$/))
  )
}

export const isSalesforceLightningLead = (url: string) => {
  return isSalesforceLightning(url) && url.includes('/Lead/')
}

export const isGmailUrl = (url: string | null) => {
  return !!url && url.includes('mail.google.com')
}

export const isMeetUrl = (url: string | null) => {
  return !!url && url.includes('meet.google.com')
}

export const isOutlookUrl = (url: string | null) => {
  return (
    !!url &&
    (url.includes('outlook.live.com') ||
      url.includes('outlook.office.com') ||
      url.includes('outlook.office365.com'))
  )
}

export const urlParams = () => {
  return qs.parse(location.search.replace('?', ''))
}

export const getCurrentSite = (URL?: string | null) => {
  const url =
    URL ||
    (typeof window !== 'undefined' && window.location.href) ||
    'https://www.crystalknows.com'

  if (isLinkedinRecruiterProfile(url)) return 'Linkedin Recruiter'
  else if (isSalesNavigatorProfile(url) || isSalesNavigatorInbox(url))
    return 'Sales Navigator'
  else if (isLinkedin(url)) return 'Linkedin'
  else if (isHubspot(url)) return 'HubSpot'
  else if (isGmailUrl(url)) return 'Gmail'
  else if (isOutlookUrl(url)) return 'Outlook'
  else if (isOutreach(url)) return 'Outreach'
  else if (isSalesforce(url)) return 'Salesforce'
  else if (isMeetUrl(url)) return 'Meet'
  else return 'Unknown'
}

export const isWrongSite = (URL: string) => {
  return (
    !isLinkedin(URL) &&
    !isHubspot(URL) &&
    !isSalesforce(URL) &&
    !isOutreach(URL) &&
    !isGmailUrl(URL) &&
    !isOutlookUrl(URL) &&
    !isMeetUrl(URL)
  )
}
