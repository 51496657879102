export default {
  apiUrl: 'https://test.crystalknows.com',
  enrichmentApiUrl: 'https://crystal-ball-enrichment.staging.crystalknows.com',
  appUrl: 'https://www.crystalknows.com',
  cookieDomain: 'crystalknows.com',
  devMode: false,
  hubURL: 'https://hub.crystalknows.com',
  marketingUrl: 'https://www.crystalknows.com',
  outreachClientId: '',
  pk_live: '',
  screenshotApiUrl:
    'https://fvxxcux5h6.execute-api.us-east-1.amazonaws.com/prod',
  rudderStackWriteKey: '',
  useDevtoolsExtension: false,
  useFullStory: true,
  useHubspotAnalytics: true,
  slack_url: 'https://hooks.slack.com',
}
